<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row
        class="flex-no__wrap ml-auto wanted-search-input-wrapper"
        type="flex"
        style="justify-content: flex-end"
      />
    </a-row>

    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="tableProps"
    >
      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: item.name,
              params: { id: item.id },
              query: { type: item.type }
            })
          "
        >
          <b v-if="item.id">({{ item.id }})</b> {{ item.title[$i18n.locale] }}
        </div>
      </template>
    </a-table>
  </a-row>
</template>

<script>
export default {
  data: () => ({
    columns: [
      {
        title: "Название",
        key: "title",
        class: "wanted-fio-td",
        scopedSlots: { customRender: "name" }
      }
    ],
    tableProps: [
      {
        title: {
          oz: "Кунлик қишлоқ хўжалиги маҳсулотлари нархлари",
          uz: "Kunlik qishloq xo'jaligi mahsulotlari narxlari",
          ru: "Ежедневные цены на сельскохозяйственную продукцию",
          en: "Daily prices of agricultural products"
        },
        id: 268,
        name: "posts",
        type: 8
      },
      {
        title: {
          oz: "Янгиликлар",
          uz: "Yangiliklar",
          ru: "Новости",
          en: "News"
        },
        id: 13,
        name: "posts"
      },
      {
        title: {
          oz: "Яшил иқтисодиёт",
          uz: "Yashil iqtisodiyot",
          ru: "Зеленая экономика",
          en: "Green economy"
        },
        id: 52,
        name: "posts"
      },
      {
        title: {
          oz: "Ўзбекистон янгиликлари",
          uz: "O'zbekiston yangiliklari",
          ru: "Новости Узбекистана",
          en: "Uzbekistan news"
        },
        id: 248,
        name: "posts"
      },
      {
        title: {
          oz: "Тадбирлар",
          uz: "Tadbirlar",
          ru: "Мероприятия",
          en: "Events"
        },
        id: 201,
        name: "posts"
      },
      {
        title: {
          oz: "Тадбирлар (Гендер тенглик)",
          uz: "Tadbirlar (Gender tenglik)",
          ru: "Мероприятия (Гендерное равенство)",
          en: "Events (Gender equality)"
        },
        id: 91,
        name: "posts"
      },
      {
        title: {
          oz: "Эълон, анонслар ва шарҳлар",
          uz: "E'lon, anonslar va sharhlar",
          ru: "Объявления, анонсы и обзоры",
          en: "Announcements, announs and reviews"
        },
        id: 202,
        name: "posts"
      },
      {
        title: {
          oz: "Маърузалар ва интервьюлар",
          uz: "Ma'ruzalar va intervyular",
          ru: "Лекции и интервью",
          en: "Lectures and interviews"
        },
        id: 203,
        name: "posts"
      },
      {
        title: {
          oz: "ОАВ биз ҳақимизда",
          uz: "OAV biz haqimizda",
          ru: "СМИ о нас",
          en: "Mass Media about us"
        },
        id: 204,
        name: "posts"
      },
      {
        title: {
          oz: "Эълонлар",
          uz: "E'lonlar",
          ru: "Объявления",
          en: "Announcements"
        },
        id: 146,
        name: "announcements",
        type: 3
      },
      {
        title: {
          oz: "Маънавият ва маърифат",
          uz: "Ma'naviyat va ma'rifat",
          ru: "Духовность и Просветление",
          en: "Spirituality and Enlightenment"
        },
        id: 97,
        name: "posts"
      },
      {
        title: {
          oz: "Коррупцияга қарши курашиш тадбирлари",
          uz: "Korrupsiyaga qarshi kurashish tadbirlari",
          ru: "Антикоррупционные события",
          en: "Anti-corruption events"
        },
        id: 176,
        name: "events",
        type: 4
      },
      {
        title: {
          oz: "Жаҳон Банки билан қўшма лойиҳалар",
          uz: "Jahon Banki bilan qo'shma loyihalar",
          ru: "Совместные проекты со Всемирным Банком",
          en: "Joint projects with the World Bank"
        },
        id: 458,
        name: "posts"
      },
      {
        title: {
          oz: "Халқаро ҳамкорлик,  семинар ва тренинглар",
          uz: "Xalqaro hamkorlik, seminar va treninglar",
          ru: "Международное сотрудничество, семинары и тренинги",
          en: "International cooperation, seminars and trainings"
        },
        name: "posts",
        id: 378
      },
      {
        title: {
          oz: "Мақолалар (Давлат тилида иш юритиш)",
          uz: "Maqolalar (Davlat tilida ish yuritish)",
          ru: "Статьи (Ведение бизнеса на государственном языке",
          en: "Articles (Operating in the State Language)"
        },
        name: "posts",
        id: 520
      },
      {
        title: {
          oz: "Ташаббусли бюджет",
          uz: "Tashabbusli budjet",
          ru: "Инициативный бюджет",
          en: "Initiative budget"
        },
        name: "posts",
        id: 527
      },
      // {
      //   title: "Речи министра",
      //   id: 96,
      //   name: "posts"
      // },
      // {
      //   title: "Министр и молодежь",
      //   id: 97,
      //   name: "posts"
      // },
      // {
      //   title: {
      //     oz: "СМИ о нас",
      //     uz: "СМИ о нас",
      //     ru: "СМИ о нас",
      //     en: "СМИ о нас"
      //   },
      //   id: 51,
      //   name: "mass"
      // },
      // {
      //   title: "Медиа план",
      //   id: 53,
      //   name: "mediaplan"
      // },
      // {
      //   title: "Духовность и просвещение",
      //   id: 55,
      //   name: "posts"
      // },
      {
        title: {
          oz: "Фотогаллерея",
          uz: "Fotogalereya",
          ru: "Фотогаллерея",
          en: "Photo gallery"
        },
        name: "gallery"
      },
      {
        title: {
          oz: "Видеогаллерея",
          uz: "Videogalereya",
          ru: "Видеогаллерея",
          en: "Video gallery"
        },
        name: "video"
      },
      {
        title: {
          oz: "Жамоатчилик кенгаши таркиби",
          uz: "Jamoatchilik kengashi tarkibi",
          ru: "Состав Общественного Совета",
          en: "Composition of the Public Council"
        },
        name: "public-council"
      }
      // {
      //   title: "FAQ",
      //   id: 52,
      //   name: "faq"
      // }
    ]
  }),
  methods: {}
}
</script>

<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
/*.ministry .ant-table-pagination.ant-pagination {
  display: none;
}*/
</style>
